import React, { useState } from "react";

import styles from "./styles.module.scss";
import FirstStep from "./FirstStep/FirstStep";
import SecondStep from "./SecondStep/SecondStep";
import ThirdStep from "./ThirdStep/ThirdStep";
import axios from "axios";

export default function Form({
  setStep,
  step,
}: {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [name, setName] = useState("");
  const [business, setBusiness] = useState("");
  const [isSendError, setIsSendError] = useState(false);

  function nextStep() {
    setStep((cur) => cur + 1);
  }

  async function sendMessage(captcha: string) {
    try {
      await axios.post(
        `https://bovatech.cc/v1/contacts`,
        {
          username: name,
          field: business,
          captcha_token: captcha,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      setIsSendError(true);
    }
  }

  return (
    <div className={styles.container} id="connectUsForm">
      <div className={styles.header}>
        <h5 className={styles.title}>
          Контактная информация <b>{step}</b>/3
        </h5>
      </div>
      <div className={styles.body}>
        {step === 1 && (
          <FirstStep nextStep={nextStep} name={name} setName={setName} />
        )}
        {step === 2 && (
          <SecondStep
            business={business}
            setBusiness={setBusiness}
            nextStep={nextStep}
            sendMessage={sendMessage}
          />
        )}
        {step === 3 && <ThirdStep isSendError={isSendError} />}
      </div>
      <div className={styles.footer}>
        {step === 1 && (
          <p>Мы свяжемся с вами по указанному Telegram аккаунту </p>
        )}
      </div>
    </div>
  );
}
